<template>
  <div class="LOGIN">
    <div class="login_center">
      <div class="haocheng"></div>
      <div class="reacte">
        <img src="@/assets/chilun_xj.png" />
        <p class="cre">创建你的众智平台账号</p>
        <p>注册成功后，你可以享受 专业内容知识服务众智平台 旗下所有产品和服务</p>
        <div class="native_left">
          <el-link
            @click="native_home"
            target="_blank"
            :underline="false"
            style="color:#CBCBCC"
          >知识头条</el-link>|
          <el-link
            @click="native_resource"
            target="_blank"
            style="color:#CBCBCC"
            :underline="false"
          >智能问答</el-link>|
          <el-link
            @click="native_resource"
            target="_blank"
            style="color:#CBCBCC"
            :underline="false"
          >资源交易</el-link>
        </div>
      </div>
      <div class="ant">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :default-active="activeIndex"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            <img src="@/assets/icon/手机.png" />手机注册
          </el-menu-item>
          <el-menu-item index="2" center>
            <img src="@/assets/icon/email_xs.png" />邮箱注册
          </el-menu-item>
        </el-menu>
        <!-- 手机号 邮箱 注册 -->
        <div class="input">
          <el-input v-model="loginForm.loginName" placeholder="昵称" maxlength="8"></el-input>
          <el-input v-model="loginForm.phone" placeholder="手机号" v-if="!show_who" @change="phonede"></el-input>
          <el-input v-model="loginForm.email" placeholder="邮箱" v-if="show_who"></el-input>
          <el-input
            id="code"
            class="code"
            v-model="code"
            maxlength="14"
            placeholder="验证码"
            style="display:inline;"
          ></el-input>
          <div class="login-code" @click="refreshCode" style="display:inline-block;">
            <!--验证码组件-->
            <s-identify :identifyCode="identifyCode"></s-identify>
          </div>
          <!-- 手机 -->
          <div v-if="!show_who">
            <el-input
              v-model="loginForm.verify_phone"
              placeholder="短信验证码"
              style="display:inline;margin-right:5px;"
            ></el-input>
            <el-button
              type="info"
              v-show="messageCode"
              style="background:#a6a9ad;width:114px;"
              @click.native="getRebackMessageCode()"
              round
            >
              <span></span>获取验证码
            </el-button>
            <el-button
              type="info"
              v-show="!messageCode"
              style="background:#a6a9ad;width:140px;"
              round
            >
              (
              <span class="countDown">{{ count_down }}</span>s)后重新获取
            </el-button>
          </div>
          <!-- 邮箱 -->
          <div v-if="show_who">
            <el-input
              v-model="loginForm.verify_email"
              placeholder="邮箱验证码"
              style="display:inline;margin-right:5px;"
            ></el-input>
            <el-button
              type="info"
              v-show="messageCode"
              style="background:#a6a9ad;width:114px;"
              @click.native="getRebackMessageCode()"
              round
            >
              <span></span>获取验证码
            </el-button>
            <el-button
              type="info"
              v-show="!messageCode"
              style="background:#a6a9ad;width:140px;"
              round
            >
              (
              <span class="countDown">{{ count_down }}</span>s)后重新获取
            </el-button>
          </div>
          <!-- 个人领域 -->
          <el-select v-model="loginForm.industry" placeholder="擅长领域">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="nv">
            <el-tooltip
              effect="dark"
              :hide-after="3000"
              placement="bottom"
              content="至少8个字符且含数字、英文字母,不能设置特殊字符"
            >
              <el-input v-model="loginForm.passWord" minlength="8" show-password placeholder="密码"></el-input>
            </el-tooltip>
            <el-input
              v-model="loginForm.passWord_verify"
              minlength="8"
              show-password
              placeholder="确认密码"
            ></el-input>
            <el-button @click="register" round>注册</el-button>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom_left">
            <el-checkbox v-model="checked"></el-checkbox>同意并遵守
            <el-link type="primary" :underline="false" @click="dialogVisible = true">服务条款</el-link>
          </div>
          <div class="bottom_right">
            已有账号？
            <el-link type="primary" @click="goLogin" :underline="false">去登录</el-link>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="智荟网服务条款"
      :visible.sync="dialogVisible"
      width="90%"
      height="100%"
      :modal-append-to-body="false"
      :before-close="handleClose"
    >
      <div>
        <h3>1.1使用规则</h3>
        <p>1.用户注册成功后，智荟网将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
        <p>2.用户须对在智荟网的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则智荟网有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。</p>
        <p>3.用户直接或通过各类方式间接使用智荟网服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用智荟网所提供的全部服务。</p>
        <p>4.智荟网是一个众智化服务的平台，用户通过智荟网发表的信息为公开的信息，其他第三方均可以通过智荟网获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在智荟网上进行发表。</p>
        <p>5.用户承诺不得以任何方式利用智荟网直接或间接从事违反中国法律以及社会公德的行为，智荟网有权对违反上述承诺的内容予以删除。</p>
        <p>6.用户不得利用智荟网服务制作、上载、复制、发布、传播或者转载如下内容：</p>
        <p>反对宪法所确定的基本原则的；</p>
        <p>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>损害国家荣誉和利益的；</p>
        <p>煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</p>
        <p>破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>含有法律、行政法规禁止的其他内容的信息。</p>

        <p>7.智荟网有权对用户使用智荟网的情况进行审查和监督，如用户在使用智荟网时违反任何上述规定，智荟网或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用智荟网的权利）以减轻用户不当行为造成的影响。</p>

        <h3>2.2知识产权</h3>智荟网尊重和鼓励智荟网用户创作的内容，认识到保护知识产权对智荟网生存与发展的重要性，承诺将保护知识产权作为智荟网运营的基本原则之一。用户在智荟网上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权均归用户本人所有。用户可授权第三方以任何方式使用。
        <h3>2.3知识币交易</h3>
        <p>1.知识币是专供智荟网用户在智荟网平台使用的虚拟币，知识币可用于智荟网平台上购买和消费内容产品。除此之外，不得用于其他用途。</p>
        <p>2.如果用户使用他人代充或以其他违规方式充值造成其他用户或第三方权益受损时，不得因此要求智荟网作任何补偿或赔偿，智荟网保留随时冻结其帐户余额、暂停或终止其使用各项充值服务及禁用其帐户的权利。如智荟网有理由相信用户的知识币帐户或使用情况涉及违规充值、作弊或异常状况，智荟网有权拒绝该用户继续使用知识币，并按本协议采取冻结、禁用帐户等相关措施。</p>
        <p>3.知识币和人民币的兑换比例以用户购买知识币的服务页面显示为准。智荟网有权根据运营情况随时变更兑换比例，并在用户购买知识币的服务页面显示。</p>
        <p>4.知识币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。用户间交易知识币构成对本协议的违反，智荟网有权不通知用户而采取适当措施，以确保智荟网不为违规用户提供知识币交易的平台服务。</p>
        <p>5.智荟网支持知识币提现功能。</p>
        <h3>2.4免责声明</h3>
        <p>1.智荟网不能对用户发表的回答或评论的正确性进行保证。用户在智荟发表的内容仅表明其个人的立场和观点，并不代表智荟的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。智荟不承担任何法律及连带责任。</p>
        <p>2.智荟不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。</p>
        <p>3.对于因不可抗力或智荟不能控制的原因造成的网络服务中断或其它缺陷，智荟不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SIdentify from "@/components/sidentify";
import { mapMutations } from "vuex";
import { DELETE, QUERYED, INSERT, UPDATE, INSERTS } from "@/services/dao.js";
import { integralAddTen } from "@/js/common";
import axios from "axios";
export default {
  components: { SIdentify },
  data() {
    return {
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "", //text框输入的验证码
      activeIndex: "1",
      loginForm: {
        loginName: "",
        passWord: "",
        phone: "", //填写的手机号
        email: "", //填写的邮箱地址
        verify: "",
        verify_email: "", // 填写的邮箱验证码
        verify_phone: "", // 填写的手机短信验证码
        passWord_verify: "", // 确认密码
        industry: "" // 个人擅长领域
      },
      checked: false,
      show_who: false, //控制 邮箱、手机注册相应子显示板块儿
      messageCode: true, // 短信验证码重发
      menu_key: 1, // 显示板块
      cb_message_code: "", //后台返回短信验证码
      cb_email_code: "", //后台返回邮箱验证码
      count_down: "60", // 60倒计时
      first_phone: "", // 获取短信验证码的手机号
      first_email: "", // 获取邮箱验证码的邮箱地址
      flag_1: false, // 返回的信息
      default_img:
        "/resources/trading/upload/jpg/211dae9c-dbb6-40e8-b3f5-261597ca0523.jpg", // 默认注册头像
      phoneIsHave: false, // 手机号是否被注册过
      emailIsHave: false, // 邮箱号是否被注册过
      dialogVisible: false,
      options: [
        {
          value: "计算机科学",
          label: "计算机科学"
        },
        {
          value: "材料科学",
          label: "材料科学"
        },
        {
          value: "电子电工",
          label: "电子电工"
        },
        {
          value: "教育学",
          label: "教育学"
        },
        {
          value: "图书情报",
          label: "图书情报"
        },
        {
          value: "合成生物学",
          label: "合成生物学"
        },
        {
          value: "人工智能",
          label: "人工智能"
        },
        {
          value: "机械工程",
          label: "机械工程"
        },
        {
          value: "其他",
          label: "其他"
        }
      ]
    };
  },
  created() {
    // this.init();
    this.refreshCode();
    // console.log(this.menu_key)
  },

  methods: {
    // ...mapMutations(["changeLogin"]),
    // ...mapMutations(["changeLoginUser"]),

    // 获取短信验证码倒计时
    getMessageCode() {
      // console.log("点击")
      this.messageCode = false;
      let time = 59;
      let t = setInterval(() => {
        this.count_down = time;
        time--;
        if (time < 0) {
          this.count_down = 60;
          clearInterval(t);
          this.messageCode = true;
        }
      }, 1000);
    },
    async phonede() {
      //手机号是否注册
      let search = await QUERYED(
        "post",
        "",
        'PCUsers(where:{phone:{_eq:"' + this.loginForm.phone + '"}}) {id}'
      );
      // console.log("search_affected_rows_loginName",search);
      // console.log("查找数据库 该手机号是否未被注册: ",search.data.PCUsers.length>0)
      if (search.data.PCUsers.length > 0) {
        this.$message.warning("该手机号已被注册!");

        return;
      } else {
        this.$message.warning("该手机号暂未注册!");
        return;
      }
    },
    // 拿到后台短信验证码
    async getRebackMessageCode() {
      let reg_phone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let reg_email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g;
      // 验证输入手机号或者邮箱合法性
      if (this.menu_key == 1) {
        // 手机注册
        if (this.loginForm.phone != "") {
          if (!reg_phone.test(this.loginForm.phone)) {
            this.$message.warning("请填写正确的手机号!");
            return;
          }

          // 根据用户输入的手机号 查找数据库 该手机号是否未被注册
          // let search = await QUERYED(
          //   "post",
          //   "",
          //   'PCUsers(where:{phone:{_eq:"' + this.loginForm.phone + '"}}) {id}'
          // );
          // console.log("search_affected_rows_loginName",search);
          // console.log("查找数据库 该手机号是否未被注册: ",search.data.PCUsers.length>0)
          // if (search.data.PCUsers.length > 0) {
          //   this.$message.warning("该手机号已被注册!");
          //   this.phoneIsHave = true;
          //   return;
          // } else {
          //   this.$message.warning("该手机号暂未注册!");
          //   return;
          // }

          this.getMessageCode(); // 手机号对且没被注册就进入倒计时
          // 请求后台发送短信验证码
          await axios
            .post(
              this.api.LoginURL.concat("/user/sendVCode"),
              {
                phone: this.loginForm.phone
              },
              {
                headers: {
                  // 'Access-Control-Allow-Origin':'*',  //解决cors头问题
                  // 'Access-Control-Allow-Credentials':'true', //解决session问题
                  "Content-Type": "application/json" //将表单数据传递转化为form-data类型
                }
              }
            )
            .then(res => {
              // console.log("拿到后台短信验证码cb: ",res);
              // 给 message_code赋值
              if (res.data.success) {
                // 发送验证码成功才存入手机号
                this.first_phone = this.loginForm.phone;
              } else {
                this.$message.warning(res.data.respMsg);
                return;
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.$message.warning("请填写手机号!");
          return;
        }
      } else {
        // 邮箱注册
        if (this.loginForm.email != "") {
          if (!reg_email.test(this.loginForm.email)) {
            this.$message.warning("请填写正确的邮箱地址!");
            return;
          }

          // 根据用户输入的邮箱 查找数据库 该邮箱是否未被注册
          let search = await QUERYED(
            "post",
            "",
            'PCUsers(where:{email:{_eq:"' + this.loginForm.email + '"}}) {id}'
          );
          // console.log("search_affected_rows_loginName",search);
          // console.log("输入的邮箱 查找数据库 该邮箱是否未被注册: ",search.data.PCUsers.length>0)
          if (search.data.PCUsers.length > 0) {
            this.$message.warning("该邮箱已被占用!");
            this.emailIsHave = true;
            return;
          }

          this.getMessageCode(); // 邮箱地址对且没被注册就进入倒计时

          // 请求后台发送邮箱验证码
          await axios
            .post(
              this.api.LoginURL.concat("/user/registerByEmail"),
              {
                email: this.loginForm.email,
                loginName: this.loginForm.loginName
              },
              {
                headers: {
                  // 'Access-Control-Allow-Origin':'*',  //解决cors头问题
                  // 'Access-Control-Allow-Credentials':'true', //解决session问题
                  "Content-Type": "application/json" //将表单数据传递转化为form-data类型
                }
              }
            )
            .then(res => {
              // console.log("拿到后台验证码cb: ",res);
              // 给 message_code赋值
              if (res.data.success) {
                // 发送验证码成功才存入邮箱地址
                this.first_email = this.loginForm.email;
              } else {
                this.$message.warning(res.data.respMsg);
                return;
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.$message.warning("请填写邮箱地址!");
          return;
        }
      }
      // console.log("点击拿验证码");
    },

    // 注册
    async register() {
      let reg_phone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let reg_email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      // re.test(this.loginForm.loginName)  验证是否全部为空
      //验证 昵称
      if (this.loginForm.loginName != "") {
        if (re.test(this.loginForm.loginName) == true) {
          this.$message.warning("昵称不能为空！");
          return;
        }
        // 根据用户输入的昵称 查找数据库 该昵称是否未被注册
        let search = await QUERYED(
          "post",
          "",
          'PCUsers(where:{loginName:{_eq:"' +
            this.loginForm.loginName +
            '"}}) {id}'
        );
        if (search.data.PCUsers.length > 0) {
          this.$message.warning("该昵称已被占用!");
          return;
        }
      } else {
        this.$message.warning("请输入昵称");
        return;
      }

      // 验证手机号或者邮箱
      if (this.menu_key == 1) {
        if (this.loginForm.phone != "") {
          if (!reg_phone.test(this.loginForm.phone)) {
            this.$message.warning("请填写正确的手机号!");
            return;
          }
        } else {
          this.$message.warning("请填写手机号!");
          return;
        }
      } else {
        if (this.loginForm.email != "") {
          if (!reg_email.test(this.loginForm.email)) {
            this.$message.warning("请填写正确的邮箱地址!");
            return;
          }
        } else {
          this.$message.warning("请填写邮箱地址!");
          return;
        }
      }

      // 验证码
      if (this.code == "") {
        this.$message({
          message: "请输入图形验证码",
          type: "warning",
          duration: "1000"
        });
        return;
      } else {
        if (this.identifyCode !== this.code) {
          this.code = "";
          this.identifyCode = "";
          this.$message({
            message: "请输入正确的图形验证码",
            type: "warning",
            duration: "1000"
          });
          return;
        }
      }

      // 验证短信/邮箱验证码
      // menu_Key = 1 则是手机注册 2为邮箱注册
      if (this.menu_key == 1) {
        // 手机注册
        // console.log("手机注册");
        if (this.loginForm.phone != "") {
          let reg_phone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
          if (!reg_phone.test(this.loginForm.phone)) {
            this.$message.warning("请填写正确的手机号!");
            return;
          } else {
            // 根据用户输入的手机号 查找数据库 该手机号是否未被注册
            // let search = await QUERY("post","",'PCUsers(where:{phone:{_eq:"'+this.loginForm.phone+'"}}) {id}');
            // console.log("search_affected_rows_loginName",search);
            // console.log("查找数据库 该手机号是否未被注册: ",search.data.PCUsers.length>0)
            if (this.phoneIsHave) {
              this.$message.warning("该手机号已被注册!");
              return;
            }
          }

          // 验证短信验证码是否为空
          if (this.loginForm.verify_phone != "") {
            // 传入User信息 把用户填的验证码发到后台

            // console.log("register->phone");
            await axios
              .post(this.api.LoginURL.concat("/user/register"), {
                phone: this.loginForm.phone,

                vcode: this.loginForm.verify_phone
              })
              .then(res => {
                // console.log("用户填的验证码发到后台cb: ",res);
                if (!res.data.success) {
                  this.$message.warning(res.data.respMsg);
                  // console.log("66666",!res.data.success)
                  return;
                }
                this.flag_1 = res.data.success;
              })
              .catch(err => {
                console.log(err);
              });
            // 验证手机号是否更改
            if (this.first_phone != this.loginForm.phone) {
              this.$message.warning("手机号与验证码不匹配,请重新获取验证码!");
              return;
            }

            if (this.flag_1) {
              // 验证个人领域
              if (this.loginForm.industry === "") {
                this.$message.warning("请选择擅长领域!");
                return;
              }

              // console.log("this.loginForm.industry: ",typeof this.loginForm.industry);
              // 验证 密码
              if (
                this.loginForm.passWord != "" &&
                this.loginForm.passWord_verify != ""
              ) {
                let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,30}$/;
                if (!reg.test(this.loginForm.passWord)) {
                  this.$message.warning("密码必须由字母和数字组成且至少8位'");
                  return;
                }
                if (this.loginForm.passWord != this.loginForm.passWord_verify) {
                  this.$message.warning("两次密码输入不一致!");
                  return;
                }
              } else {
                this.$message.warning("密码或确认密码不能为空!");
                return;
              }

              // 验证服务条款
              if (this.checked != true) {
                this.$message({
                  message: "请勾选服务条款",
                  type: "warning",
                  duration: "1000"
                });
                return;
              }
              // 手机号注册 所有验证通过则注册
              let date = new Date();
              let info = await INSERTS(
                "POST",
                "",
                'insert_User(objects: {loginName: "' +
                  this.loginForm.loginName +
                  '",name:"' +
                  this.loginForm.loginName +
                  '", img:"' +
                  this.default_img +
                  '",loginPassword:"' +
                  this.$md5(this.loginForm.passWord) +
                  '",industry: "' +
                  this.loginForm.industry +
                  '", phone: "' +
                  this.loginForm.phone +
                  '",createTime: "' +
                  date +
                  '",concernNum:' +
                  0 +
                  ",articleNum:" +
                  0 +
                  ",level:" +
                  0 +
                  ",}) { affected_rows returning { id  }}"
              );

              // console.log("insert_手机cb: ",info);
              let flag_2 = info.data.insert_User.returning.length;
              let cb_userID = info.data.insert_User.returning[0].id;
              // console.log("flag_2: ",flag_2);
              // console.log("info.data.insert_User.returning[0].id: ",cb_userID)
              if (flag_2 == 1) {
                // console.log("info.data.insert_User.returning.length: ",flag_2)
                // console.log("insert_ZYUser_all 已经入: ",info);
                let info = await INSERTS(
                  "POST",
                  "",
                  "insert_ZYUser_all(objects: {RsUser:" +
                    cb_userID +
                    ',username:"",school:"",Belonging:"",follow:' +
                    { a: 1, b: 2 } +
                    "}) {affected_rows}"
                );

                // console.log("insert_ZYUser_all cb: ",info);

                let flag_3 = info.data.insert_ZYUser_all.affected_rows;
                if (flag_3 == 1) {
                  this.$message.success("注册成功");
                  this.$router.push("login");
                }
              }
            }
          } else {
            this.$message.warning("请填写短信验证码!");
            return;
          }
        } else {
          this.$message.warning("请填写手机号!");
          return;
        }
      } else {
        // 邮箱注册
        // console.log("邮箱注册");
        if (this.loginForm.email != "") {
          let reg_email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g;
          if (!reg_email.test(this.loginForm.email)) {
            this.$message.warning("请输入正确的邮箱地址(不支持中文)!");
            return;
          } else {
            // 根据用户输入的邮箱 查找数据库 该邮箱是否未被注册
            // let search = await QUERY("post","",'PCUsers(where:{email:{_eq:"'+this.loginForm.email+'"}}) {id}');
            // console.log("search_affected_rows_loginName",search);
            // console.log("输入的邮箱 查找数据库 该邮箱是否未被注册: ",search.data.PCUsers.length>0)
            if (this.emailIsHave) {
              this.$message.warning("该邮箱已被占用!");
              return;
            }
          }
        } else {
          this.$message.warning("请输入邮箱地址!");
          return;
        }

        // console.log("点击email")
        await axios
          .post(this.api.LoginURL.concat("/user/register"), {
            email: this.loginForm.email,

            vcode: this.loginForm.verify_email
          })
          .then(res => {
            // console.log("请求邮箱地址cb: ",res);
            if (!res.data.success) {
              this.$message.warning(res.data.respMsg);
              return;
            }
            this.flag_1 = res.data.success;
          })
          .catch(err => {
            console.log(err);
          });
        // 验证邮箱地址是否更改
        if (this.first_email != this.loginForm.email) {
          this.$message.warning("邮箱地址与验证码不匹配,请重新获取验证码!");
          return;
        }

        if (this.flag_1) {
          // 验证擅长领域，密码，服务条款

          // 验证个人领域
          if (this.loginForm.industry === "") {
            this.$message.warning("请选择擅长领域!");
            return;
          }

          // console.log("this.loginForm.industry: ",typeof this.loginForm.industry);
          // 验证 密码
          if (
            this.loginForm.passWord != "" &&
            this.loginForm.passWord_verify != ""
          ) {
            let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,30}$/;
            if (!reg.test(this.loginForm.passWord)) {
              this.$message.warning("8位及以上密码且必须同时包含数字、字母'");
              return;
            }
            if (this.loginForm.passWord != this.loginForm.passWord_verify) {
              this.$message.warning("两次密码输入不一致!");
              return;
            }
          } else {
            this.$message.warning("密码或确认密码不能为空!");
            return;
          }

          // 验证服务条款
          if (this.checked != true) {
            this.$message({
              message: "请勾选服务条款",
              type: "warning",
              duration: "1000"
            });
            return;
          }
          let date = new Date();
          // 邮箱注册 所有验证通过则注册
          let info = await INSERTS(
            "POST",
            "",
            'insert_User(objects: {loginName: "' +
              this.loginForm.loginName +
              '",name:"' +
              this.loginForm.loginName +
              '" ,img:"' +
              this.default_img +
              '",loginPassword:"' +
              this.$md5(this.loginForm.passWord) +
              '",industry: "' +
              this.loginForm.industry +
              '", email: "' +
              this.loginForm.email +
              '",createTime: "' +
              date +
              '",concernNum:' +
              0 +
              ",articleNum:" +
              0 +
              ",level:" +
              0 +
              ",}) { affected_rows returning { id  }}"
          );
          // console.log("insert_邮箱cb: ",info);
          let flag_2 = info.data.insert_User.returning.length;
          let cb_userID = info.data.insert_User.returning[0].id;
          // console.log("flag_2: ",flag_2);
          // console.log("info.data.insert_User.returning[0].id: ",cb_userID)
          if (flag_2 == 1) {
            // console.log("info.data.insert_User.returning.length: ",flag_2)
            // console.log("insert_ZYUser_all 已经入: ",info);
            let info = await INSERTS(
              "POST",
              "",
              "insert_ZYUser_all(objects: {RsUser:" +
                cb_userID +
                ',username:"",school:"",Belonging:"",follow:' +
                { a: 1, b: 2 } +
                "}) {affected_rows}"
            );

            let flag_3 = info.data.insert_ZYUser_all.affected_rows;
            if (flag_3 == 1) {
              this.$message.success("注册成功");
              integralAddTen(cb_userID)
              this.$router.push("login");
            }
          }
        }
      }
    },
    te() {
      console.log("foucs");
    },
    native_home() {
      this.$router.push({ name: "index", params: { userId: 123 } });
    },
    native_resource() {
      this.$router.push({ name: "Resource", params: { userId: 123 } });
    },
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    // menu选择框
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      if (key == 1) {
        this.show_who = false;
        this.menu_key = 1;
      } else {
        this.show_who = true;
        this.menu_key = 2;
      }
      // console.log("menu_key: ",this.menu_key)
    }
  }
};
</script>
<style scoped>
.LOGIN {
  width: 85%;
  background-image: url(~@/assets/img/Vol_006_SE182.png);
  background-size: 100% 100%;
  padding: 100px;
  /* overflow: hidden; */
}
.login_center {
  /* margin: 161px 153px 178px 143px; */
  width: 100%;
}
.login_center::after {
  display: block;
  content: "";
  clear: both;
}
.reacte {
  width: 458px;
  height: 570px;
  background-color: #1b2343;
  color: #ffffff;
  float: left;
  text-align: center;
}
.reacte img {
  width: 70px;
  margin-top: 139px;
}
.cre {
  font-size: 24px;
}
.ant {
  width: 600px;
  height: 570px;
  background-color: #ffffff;
  float: left;
  position: relative;
}
.el-menu-item {
  text-align: center;
  width: 50%;
}
.el-menu-item img {
  width: 30px;
  margin-top: -5px;
  margin-right: 6px;
}
.input {
  margin: 10px 0px 0 190px;
}
.nv {
  overflow: hidden;
}
.nv .el-link {
  line-height: 40px;
}
.el-button {
  width: 210px;
  background-color: #1b2343;
  color: #ffffff;
}
.bottom {
  width: 100%;
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid #dcdfe6;
  /* position: absolute; */
  /* bottom: 10px; */
}
.bottom_left {
  float: left;
  width: 60%;
  margin-left: 5%;
}
.el-checkbox__inner {
  border-radius: 999px;
}
.bottom .el-link {
  margin-bottom: 3px;
}
/*样式重构*/
/* .el-menu-demo >>> .el-menu-item,.is-active{
  border-bottom: 0;
  color: #303133;
}
.ant .el-menu-item{
 cursor: default;
}  */
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 20px 20px 10px;
  text-align: center;
  font-weight: 600;
  /* font-size: 26px; */
}
.el-dialog__wrapper >>> .el-dialog__title {
  line-height: 24px;
  font-size: 20px;
  color: #303133;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  height: 360px !important;
  overflow-y: scroll;
}
</style>
